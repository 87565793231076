document.addEventListener('DOMContentLoaded', () => {
  const handleRedirectToLoginAsResponse = (_event, xhr) => {
    const { responseText = '' } = xhr || {};

    if (responseText.includes('data-logged-out')) {
      // handle server returning sign-in page as response
      // i.e. if user logs out in one tab and then interacts
      // with page to send an ajax request in another tab
      window.location.reload();
    }
  };

  // e.g. pouch report
  $(document).on('ajaxSuccess', handleRedirectToLoginAsResponse);
  // e.g. outbound dispo
  $(document).on('ajaxError', handleRedirectToLoginAsResponse);
});
