/* eslint-env jquery */
/* global moment */
const autoUpdateWorkingTimeAndMaterialCost = () => {
  // Ask backend for customer related rate_sheet values
  $(
    '#screening_performance_performed_at, #screening_performance_pieces_secured',
  ).on('change', () => {
    const date = moment($('#screening_performance_performed_at').val()).format(
      'YYYY-MM-DD',
    );
    const piecesSecured = $('#screening_performance_pieces_secured').val();

    // need to use absolute path rather than relative path
    // because the path where this is called from varies
    // 1. new screening performance path
    // 2. edit path
    // 3. after submitting invalid values from new path
    // 4. after submitting invalid values from edit path
    const { pathname } = window.location;
    const requestUrl = pathname.replace(
      /screening_performances.*/,
      'screening_performances/default_values',
    );

    if (date != null && piecesSecured > 0) {
      $.ajax({
        url: requestUrl,
        data: { values: { date, pieces_secured: piecesSecured } },
        method: 'GET',
        success(response) {
          $('#screening_performance_billing_item_attributes_working_time').val(
            response.working_time,
          );
          $(
            '#screening_performance_billing_item_attributes_costs_material',
          ).val(response.material_cost);
        },
      });
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const screeningPerformanceListenerFields = $(
    '#screening_performance_performed_at, #screening_performance_pieces_secured',
  );

  if (screeningPerformanceListenerFields.length) {
    autoUpdateWorkingTimeAndMaterialCost();
  }
});
