import { Controller } from 'stimulus';

export default class ToggleElementController extends Controller {
  static targets = ['trigger', 'toggledElementWrapper', 'toggledElement'];

  static values = { enablers: Array };

  connect() {
    this.toggle();
  }

  toggle() {
    const enable = this.enablersValue.includes(this.triggerTarget.value);

    this.toggledElementTarget.disabled = !enable;
    this.toggledElementWrapperTarget.classList.toggle('hidden', !enable);
  }
}
